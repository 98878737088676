import { Injectable, Injector, inject } from '@angular/core';
import { BehaviorSubject, Observable, map, switchMap, take } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SESSION_CALL_PROPS, SessionCallComponent } from '@wndr/common/shared/features/session-call/session-call/session-call.component';
import { SessionCallProps } from '@wndr/common/shared/features/session-call/models/session-call-props';

import { User } from '../models/user';
import { Session } from '../models/session';
import { assertNonNullWithReturn } from '../utils/assert-non-null';
import { CallCredentials } from '../models/call-session/call-credentials';
import { IncomingCallNotification } from '../models/push-notification-data';

import { UserBase } from '../models/user-base';

import { CallApiService } from './call-api.service';
import { RedirectService } from './redirect.service';

const MINIMIZED_ELEMENT_CLASS = 'hidden';

/** Service to manage session calls. */
@Injectable({ providedIn: 'root' })
export class CallService {

	private readonly overlay = inject(Overlay);

	private readonly videoCallService = inject(CallApiService);

	private readonly redirectService = inject(RedirectService);

	private overlayRef: OverlayRef | null = null;

	private readonly _backgroundCall$ = new BehaviorSubject<{ participant: UserBase; } | null>(null);

	/** Is user in a call right now. */
	public readonly backgroundCall$ = this._backgroundCall$.asObservable();

	/** Shows if there is an active call. */
	public get isCallInProgress(): boolean {
		return this.overlayRef !== null;
	}

	/**
	 * Return to the active call which is in background.
	 * @throws Error if there is no active call in background.
	 */
	public returnToTheCall(): void {
		const currentCall = this._backgroundCall$.value;
		if (currentCall === null) {
			throw new Error('No active call.');
		}
		this._backgroundCall$.next(null);
		this.maximizeCallDialog();
	}

	/**
	 * Open call dialog and start a session.
	 * @param currentUser Current user.
	 * @param session Consultation session.
	 * @param features Which features are available for call.
	 * @param isVideoCall Is video call. If false, audio call will be started.
	 * @param isAnswer Whether users answers a call or initiates it.
	 * @throws Error if there is an active call.
	 */
	public openCallDialog(
		currentUser: User,
		session: Session,
		features?: SessionCallProps['uiConfiguration'],
		isVideoCall = true,
		isAnswer = false,
	): void {
		if (this.isCallInProgress) {
			throw new Error('Call is already in progresss');
		}

		this.getSessionToken(session.id, isVideoCall, isAnswer).pipe(
			take(1),
			switchMap(callCredentials => {
				const overlayRef = this.overlay.create({
					hasBackdrop: true,
					backdropClass: 'call-dialog-backdrop',
					panelClass: 'call-dialog-panel',
					positionStrategy: this.overlay.position().global()
						.centerHorizontally()
						.centerVertically(),
					disposeOnNavigation: true,
				});

				this.overlayRef = overlayRef;

				const participant = session.facilitator.id === currentUser.id ? session.client : session.facilitator;

				const componentProps: SessionCallProps = {
					caller: currentUser,
					participant,
					sessionId: session.id,
					isVideoCall,
					callCredentials,
					onMinimize: () => {
						this._backgroundCall$.next({ participant });
						this.minimizeCallDialog();
					},
					onCallEnd: () => {
						this.destroyCallDialog();
					},
					uiConfiguration: {
						cycleVideoButton: features?.cycleVideoButton ?? false,
						audioInputSelector: features?.audioInputSelector ?? false,
					},
				};

				const portal = new ComponentPortal(
					SessionCallComponent,
					null,
					Injector.create({
						providers: [
							{
								provide: SESSION_CALL_PROPS,
								useValue: componentProps,
							},
						],
					}),
				);

				overlayRef.attach(portal);

				return overlayRef.detachments().pipe(
					map(() => overlayRef),
				);
			}),
		)
			.subscribe({
				next: detachedOverlay => {
					this._backgroundCall$.next(null);
					detachedOverlay.dispose();
					this.overlayRef = null;
				},
			});
	}

	/**
	 * Runs call from received notification.
	 * @param notification Incoming call notification object.
	 */
	public openFromNotification(notification: IncomingCallNotification): void {
		this.redirectService.joinConversation(notification.sessionId, notification.isVideoCall);
	}

	private minimizeCallDialog(): void {
		const overlayRef = assertNonNullWithReturn(this.overlayRef);
		overlayRef.backdropElement?.classList.add(MINIMIZED_ELEMENT_CLASS);
		overlayRef.addPanelClass(MINIMIZED_ELEMENT_CLASS);
	}

	private maximizeCallDialog(): void {
		const overlayRef = assertNonNullWithReturn(this.overlayRef);
		overlayRef.backdropElement?.classList.remove(MINIMIZED_ELEMENT_CLASS);
		overlayRef.removePanelClass(MINIMIZED_ELEMENT_CLASS);
	}

	private destroyCallDialog(): void {
		const overlayRef = assertNonNullWithReturn(this.overlayRef);
		overlayRef.detach();
	}

	private getSessionToken(sessionId: number, isVideoCall: boolean, isAnswer: boolean): Observable<CallCredentials> {
		return this.videoCallService.getCallCredentials({ isVideoCall, sessionId, isAnswer });
	}
}
